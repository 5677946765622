<template>
  <el-row>
    <el-col :span="11">
      <div class="info"><slot name="title"></slot></div>
    </el-col>
    <el-col :span="13">
      <div class="message">
        <div class="line-height"><slot name="content1"></slot></div>
        <div class="line-height"><slot name="content2"></slot></div>
      </div>
      <slot></slot>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'removeStore',
  setup() {
    
  },
}
</script>

<style lang="scss" scoped>
.info {
  font-size: 21px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 29px;
  margin-bottom: 12px;
}
.message {
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.65);
  .line-height {
    line-height: 22px;
  }
}
</style>