<template>
  <el-row>
    <el-col :span="11">
      <div class="info">Ship from address</div>
      <div class="account_info">
        Brand your packaging by adding your company address and a phone number.
      </div>
    </el-col>
    <el-col :span="13">
      <el-card shadow="always">
        <el-radio v-model="addressRadio" :label="1"
          >Your default address</el-radio
        >
        <div class="padding hint">
          The return address will be the location where the product was printed.
        </div>
        <div class="padding hint filled" v-if="!isShowAddress">
          You have not filled in the default address.
          <span class="text" @click="$router.push('/myAccount')"
            >Fill in now</span
          >
        </div>
        <div class="padding default_address" v-else>
          <div class="text">
            {{ defaultAddress.address1 }}, {{ defaultAddress.state }}
            {{ defaultAddress.city }}, {{ defaultAddress.zip }}
          </div>
          <div class="text">{{ defaultAddress.country }}</div>
          <div class="text">{{ defaultAddress.phone }}</div>
        </div>
        <el-radio v-model="addressRadio" :label="2">Custom address</el-radio>
        <div class="padding hint" style="margin-bottom: 20px">
          Must be a valid address
        </div>
        <div v-if="addressRadio === 2" class="form_box">
          <el-form
            :model="addressForm"
            :rules="rulesAddressForm"
            ref="ruleForm"
            class="padding form"
          >
            <el-row :gutter="30">
              <el-col :span="12">
                <div class="name">Country <span class="requied">*</span></div>
                <el-form-item prop="country">
                  <el-select
                  filterable
                    v-model="addressForm.country"
                    @change="getStateList"
                  >
                    <el-option
                      v-for="item in countryList"
                      :label="item.name"
                      :value="item.code"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="name">
                  State / Province <span class="requied">*</span>
                </div>
                <el-form-item prop="state">
                  <el-select v-model="addressForm.state" @change="getCityList" filterable>
                    <el-option
                      v-for="item in stateList"
                      :label="item.name"
                      :value="item.name"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="name">City</div>
                <el-form-item prop="city">
                  <!-- <el-select v-model="addressForm.city">
                    <el-option
                      v-for="item in cityList"
                      :label="item.name"
                      :value="item.name"
                      :key="item.id"
                    ></el-option>
                  </el-select> -->

                  <el-autocomplete
                    class="inline-input"
                    v-model="addressForm.city"
                    :fetch-suggestions="querySearch"
                    value-key="name"
                  ></el-autocomplete>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="name">Postal / ZIP Code</div>
                <el-form-item prop="code">
                  <el-input v-model="addressForm.zip"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <div class="name">Address 1</div>
                <el-form-item prop="address1">
                  <el-input v-model="addressForm.address1"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <div class="name">Address 2</div>
                <el-form-item prop="address2">
                  <el-input v-model="addressForm.address2"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="name">
                  Phone number <span class="requied">*</span>
                </div>
                <el-form-item prop="phone">
                  <el-input v-model="addressForm.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-button
          type="primary"
          @click="saveShipAddress"
          class="m-left"
          :loading="isSave"
          >Save</el-button
        >
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { getWordArea } from "@/api/common.js";
import { getAddressList } from "@/api/account.js";
import { Back } from '@element-plus/icons'
export default {
  name: "ShipAddress",
  props: {
    addressForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      addressRadio: 1,
      rulesAddressForm: {
        country: [
          {
            required: true,
            message: "Please input Country",
            trigger: "change",
          },
        ],
        state: [
          {
            required: true,
            message: "Please input State / Province",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Please input Phone",
            trigger: "blur",
          },
        ],
      },
      countryList: null,
      stateList: null,
      cityList: null,
      isSave: false,
      defaultAddress: null,
      isShowAddress: false,
    };
  },
  created() {
    this.getDefaultAddress();
    this.getWordAreaInfo();
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.cityList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    // 保存地址信息
    saveShipAddress() {
      let res = {};
      if (this.addressRadio === 1) {
        res = this.filterObj(this.defaultAddress, [
          "country",
          "state",
          "city",
          "address1",
          "address2",
          "phone",
          "zip",
        ]);
      } else if (this.addressRadio === 2) {
        res = this.filterObj(this.addressForm, [
          "country",
          "state",
          "city",
          "address1",
          "address2",
          "phone",
          "zip",
        ]);
      }
      this.isSave = true;
      this.$emit("handleSave", { data: res });
    },
    // 过滤对象
    filterObj(obj, arr) {
      // obj 要过滤的对象   arr 过滤的条件，为数组
      if (typeof obj !== "object" || !Array.isArray(arr)) {
        throw new Error("参数不正确");
      }
      const result = {};
      Object.keys(obj)
        .filter((key) => arr.includes(key))
        .forEach((key) => {
          result[key] = obj[key];
        });
      console.log(result);
      return result;
    },
    // 获取全球区域信息
    async getWordAreaInfo() {
      const { data } = await getWordArea();
      this.countryList = data;
      if (!!this.addressForm.country) {
        this.getStateList();
      }
      if (!!this.addressForm.state) {
        this.getCityList();
      }
    },
    // 获取 州 / 省列表
    getStateList(e) {
      this.stateList = this.countryList.find(
        (item) => item.code === this.addressForm.country
      ).states;
      // 清空 state 和 city
      if (e) {
        this.addressForm.state = "";
        this.addressForm.city = "";
      }
    },
    // 获取城市列表
    getCityList(e) {
      this.cityList = this.stateList.find(
        (item) => item.name === this.addressForm.state
      ).cities;
      if (e) {
        this.addressForm.city = "";
      }
    },
    // 获取默认退货地址
    async getDefaultAddress() {
      try {
        const { data } = await getAddressList(0);
        if (data.length) {
          this.defaultAddress = data[0];
          this.isShowAddress = true;
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-form--label-top .el-form-item__label) {
  margin: -20px 0 -10px 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

:deep(.el-autocomplete) {
  width: 100%;
}
.el-form-item .el-select {
  width: 100%;
}
.info {
  font-size: 21px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 29px;
  margin-bottom: 12px;
}
.account_info {
  width: 75%;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.5);
  line-height: 22px;
}
.padding {
  padding-left: 30px;
  margin: 10px 0;
}
.form_box {
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;
  .form {
    margin-bottom: 10px;
    .name {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
    .requied {
      color: #ff0000;
    }
  }
}
.message {
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.65);
  .line-height {
    line-height: 22px;
  }
}
.hint {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  &.filled {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
  .text {
    margin-left: 10px;
    color: #ff2626;
    text-decoration: underline;
    cursor: pointer;
  }
}
.default_address {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .text {
    margin-bottom: 6px;
  }
}
.m-left {
  margin-left: 30px;
}
</style>
