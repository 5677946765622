<template>
  <div class="setting_box">
    <div class="w1120">
      <!-- 返回组件 -->
      <!-- <div style="margin-bottom: 20px">
        <back-arrow text="Go Back"></back-arrow>
      </div> -->
      <!-- 标题 -->
      <div class="title">
        <div class="store">Store setting</div>
        <div @click="back">
          <el-icon class="back_icon">
            <Back />
          </el-icon>
          <span class="back_text">Go Back</span>
        </div>
      </div>
      <!-- 分割线 -->
      <el-divider class="margin"></el-divider>
      <!-- 个人信息 -->
      <personal-info
        :form="formData"
        @handleSave="saveStoreSetting"
        ref="info"
      ></personal-info>
      <el-divider class="margin"></el-divider>
      <!-- 地址 -->
      <ship-address
        :address-form="address"
        @handleSave="saveStoreSetting"
        ref="address"
      ></ship-address>
      <el-divider class="margin"></el-divider>
      <!-- 订单审批 -->
      <order-approval
        :approval="approval"
        @handleSave="saveStoreSetting"
        ref="approval"
      ></order-approval>
      <el-divider class="margin"></el-divider>
      <!-- 断开链接 -->
      <!-- <remove-store>
        <template #title>Disconnect your store</template>
        <template #content1
          >99diy account won’t receive incoming orders for published products in
          your store.</template
        >
        <template #content2
          >You won’t be able to update products in your store from your 99diy
          account.</template
        >
        <el-button type="info">Disconnect</el-button>
      </remove-store> -->
      <!-- <el-divider class="margin"></el-divider> -->
      <!-- 移除店铺 -->
      <remove-store>
        <template #title>Remove store</template>
        <template #content1
          >Removing your store will completely remove all content associated
          with it.</template
        >
        <template #content2
          >There is no way back, please be careful with this option.</template
        >
        <el-button type="info" @click="removeDialog = true">Remove</el-button>
      </remove-store>
    </div>
    <!-- 页脚 -->
    <copyright-info bgc="gray" class="footer"></copyright-info>
    <!-- 确认删除店铺弹出框 -->
    <el-dialog v-model="removeDialog" title="Are you sure" width="30%">
      <span>Do you want to permanently delete your store Store name?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="removeDialog = false">Cancel</el-button>
          <el-button type="primary" @click="handleRemove"
            >Delete store</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import PersonalInfo from "./components/personalInfo.vue";
import ShipAddress from "./components/shipAddress.vue";
import OrderApproval from "./components/orderApproval.vue";
import RemoveStore from "./components/removeStore.vue";
import CopyrightInfo from "@/views/store/components/copyrightInfo.vue";
import backArrow from "@/components/backArrow.vue";
import { Back } from "@element-plus/icons";
import { getStoreDetail, updateStoreInfo, deleteStore } from "@/api/myStore.js";
import $store from "@/store/index";

export default {
  components: {
    CopyrightInfo,
    PersonalInfo,
    ShipAddress,
    OrderApproval,
    RemoveStore,
    backArrow,
    Back,
  },
  data() {
    return {
      formData: {
        // 个人信息表单数据
        name: "",
        website: "",
      },
      address: {}, // 店铺地址信息
      approval: "", // 订单审批时间
      defaultAddress: {
        // 地址表单默认数据
        country: "",
        state: "",
        city: "",
        address1: "",
        address2: "",
        phone: "",
        zip: "",
      },
      removeDialog: false, // 删除店铺确认框
    };
  },
  created() {
    this.getStoreDetail();
  },
  methods: {
    //退回
    back() {
      let { state } = this.$route.query;
      if (!!Number(state)) {
        this.$router.push("/store?state=1");
        // $store.commit("setStoreCode", this.$route.params.storeCode);
        sessionStorage.setItem("setStoreCode", this.$route.params.storeCode);
      } else {
        this.$router.push("/store");
      }
    },

    // 获取店铺详情
    async getStoreDetail() {
      try {
        const { data } = await getStoreDetail(this.$route.params.storeCode);
        const { name, website_thirdparty, deliver_address, order_approval } =
          data;
        this.formData.name = name;
        this.formData.website = website_thirdparty;
        this.address = deliver_address || this.defaultAddress;
        this.approval = order_approval;
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 保存店铺信息
    async saveStoreSetting(obj) {
      const data = {
        storeCode: this.$route.params.storeCode,
        storeName: obj.name,
        deliverAddress: obj.data,
        orderApproval: obj.orderApproval,
      };
      try {
        const { message } = await updateStoreInfo(data);
        this.$refs.info.isSave = false;
        this.$refs.address.isSave = false;
        this.$refs.approval.isSave = false;
        this.$message.success(message);
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 删除店铺
    async handleRemove() {
      try {
        const { message } = await deleteStore(this.$route.params.storeCode);
        this.$message.success(message);
        sessionStorage.removeItem("setStoreCode"); 
        this.$router.push("/store?state=0");
      } catch (e) {
        this.$message.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/myAccount/account.scss";
:deep(.el-radio__input.is-checked + .el-radio__label) {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
:deep(.el-radio__input + .el-radio__label) {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
:deep(.el-radio__input.is-checked .el-radio__inner) {
  border-color: #1eab7f;
  background: #1eab7f;
}

.title {
  display: flex;
  justify-content: space-between;
  .back_icon {
    font-size: 20px;
    font-weight: 600;
    vertical-align: top;
    line-height: 50px;
  }
  .back_text {
    margin-left: 10px;
    font-weight: 600;
    cursor: pointer;
    line-height: 45px;
  }
}

.setting_box {
  background-color: #fff;
  // width: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFang SC;
  .w1120 {
    padding: 50px 0 40px 0;
    width: 1120px;
    margin: 0 auto;
  }
  .store {
    font-size: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
  .margin {
    margin: 50px 0;
  }
}
</style>
