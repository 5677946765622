<template>
    <div class="statement_box" :class="bgc">
      <div class="statement">
        © 2021 99diy, powered by 99diy Group, All rights reserved.
      </div>
    </div>
</template>

<script>
export default {
  name: 'CopyrightInfo',
  props: {
    bgc: {
      type: String,
      default: 'white'
    }
  }
}
</script>
<style scoped lang="scss">
.statement_box {
	height: 70px;
	text-align: center;
	line-height: 70px;
  .statement {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
  }
}
.white { 
	background: #fff;
}
.gray {
  background: rgba(0, 0, 0, 0.02);
}
</style>