<template>
  <el-row>
    <el-col :span="11">
      <div class="info">Personal information</div>
      <div class="account_info">
        You can find your account related information here.
      </div>
    </el-col>
    <el-col :span="13">
      <el-form ref="form" :model="form" :rules="ruleFormData">
        <div class="name">Store name</div>
        <el-form-item prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <div class="name">Website</div>
        <el-form-item prop="website">
          <el-input v-model="form.website" disabled></el-input>
        </el-form-item>
        <el-form-item style="width: 450px">
          <el-button type="primary" @click="saveForm" :loading="isSave">Save</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'PersonalInfo',
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ruleFormData: { // 表单校验
        name: [
          {
            required: true,
            message: 'Please input name',
            trigger: 'blur',
          },
          {
            min: 5,
            message: 'Length should be equal or greater than 5',
            trigger: 'blur',
          }
        ],
        // website: [
        //   { // 判断 website 是否合法
        //     pattern: "^((https|http|ftp|rtsp|mms)?://)" +
        //         "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" +
        //         "(([0-9]{1,3}\.){3}[0-9]{1,3}" +
        //         "|" +
        //         "([0-9a-z_!~*'()-]+\.)*"  +
        //         "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." +
        //         "[a-z]{2,6})" +
        //         "(:[0-9]{1,4})?" +
        //         "((/?)|"  +
        //         "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$",
        //     trigger: 'blur',
        //     message: 'please enter a valid URL'
        //   }
        // ]
      },
      isSave: false
    }
  },
  methods: {
    // 保存个人信息
    saveForm() {
      const obj = this.filterObj(this.form, ["name"])
      this.isSave = true
      this.$emit('handleSave', obj)
    },
    // 过滤对象
    filterObj(obj, arr) { // obj 要过滤的对象   arr 过滤的条件，为数组
      if (typeof (obj) !== "object" || !Array.isArray(arr)) {
        throw new Error("参数不正确")
      }
      const result = {}
      Object.keys(obj).filter((key) => arr.includes(key)).forEach((key) => {
        result[key] = obj[key]
      })
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  font-size: 21px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 29px;
  margin-bottom: 12px;
}
.account_info {
  width: 75%;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.5);
  line-height: 22px;
}
.name {
  margin: 8px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
</style>