<template>
  <el-row>
    <el-col :span="11">
      <div class="info">Order approval</div>
      <div class="account_info">
        Choose when to send orders to production. After you send an order to
        production, you cannot edit any details or cancel it. Changes for this
        setting will apply only for upcoming orders.
      </div>
    </el-col>
    <el-col :span="13">
      <el-card shadow="always">
        <el-radio v-model="approvalRadio" :label="1"
          >Manual Order Review</el-radio
        >
        <div class="padding hint">
          Orders are sent to production after you've clicked "Confirm order" in
          the order panel. You need to pay orders before they can be processed.
        </div>
        <el-radio v-model="approvalRadio" :label="10"
          >Automatically In 10 Minutes</el-radio
        >
        <br />
        <el-radio v-model="approvalRadio" :label="2"
          >Automatically In 1 hour</el-radio
        >
        <br />
        <el-radio v-model="approvalRadio" :label="3"
          >Automatically In 12 hours</el-radio
        >
        <br />
        <el-radio v-model="approvalRadio" :label="4"
          >Automatically In 24 hours</el-radio
        >
        <el-radio v-model="approvalRadio" :label="5"
          >Automatically at a certain time every day</el-radio
        >
        <div class="padding hint">
          Note that the time will be calculated based on your timezone settings.
        </div>
        <el-select
          v-model="time"
          placeholder="00:00"
          style="width: 82px; margin: 20px 16px 0 30px"
          :disabled="approvalRadio !== 5"
        >
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="timeSlot"
          placeholder="PM"
          style="width: 74px"
          :disabled="approvalRadio !== 5"
        >
          <el-option
            v-for="item in soltOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div>
          <el-button
            type="primary"
            class="btn"
            @click="saveApproval"
            :loading="isSave"
            >Save</el-button
          >
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { getUserWalletInfo } from '@/api/account'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
export default {
  name: 'OrderApproval',
  props: {
    approval: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const isSave = ref(false)
    const approvalRadio = ref(1)
    const timeSlot = ref('AM')
    const soltOptions = ref([
      {
        value: 'PM',
        label: 'PM',
      },
      {
        value: 'AM',
        label: 'AM',
      },
    ])
    const time = ref(0)
    const timeOptions = ref([
      {
        value: 0,
        label: '00:00',
      },
      {
        value: 1,
        label: '01:00',
      },
      {
        value: 2,
        label: '02:00',
      },
      {
        value: 3,
        label: '03:00',
      },
      {
        value: 4,
        label: '04:00',
      },
      {
        value: 5,
        label: '05:00',
      },
      {
        value: 6,
        label: '06:00',
      },
      {
        value: 7,
        label: '07:00',
      },
      {
        value: 8,
        label: '08:00',
      },
      {
        value: 9,
        label: '09:00',
      },
      {
        value: 10,
        label: '10:00',
      },
      {
        value: 11,
        label: '11:00',
      },
      {
        value: 12,
        label: '12:00',
      },
    ])
    watch(
      () => props.approval,
      (newVal) => {
        formatApproval(newVal)
        if (!newVal) {
          approvalRadio.value = 1
        }
      }
    )
    // 格式化审批时间
    const formatApproval = (value) => {
      value = value || ''
      let arr = value.split(':')
      arr = arr.map(Number)
      arr[1] = arr[1] || 0
      approvalRadio.value = arr[0]
      if (arr[1] >= 0 && arr[1] <= 12) {
        time.value = arr[1]
        timeSlot.value = 'AM'
      } else if (arr[1] > 12 && arr[1] <= 24) {
        time.value = arr[1] - 12
        timeSlot.value = 'PM'
      }
    }
    const store = useStore()
    const router = useRouter()
    // 保存订单审批的时间
    const saveApproval = async () => {
      isSave.value = true
      let wallet = null
      let brand = null

      //获取余额
      try {
        const { data } = await getUserWalletInfo()
        wallet = data?.wallet
        brand = data?.cardInfo?.brand
      } catch (error) {
        isSave.value = false
        ElMessage.error(error)
        return
      }
      // 1.未绑定信用卡
      if (!brand) {
        //2.余额小于等于0
        if (Number(wallet) <= 0) {
          isSave.value = false
          ElMessageBox.confirm(
            'The balance is insufficient or the credit card is not bound, please recharge the balance or bind the credit card.',
            'Warning',
            {
              confirmButtonText: 'Right Now',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }
          )
            .then(() => {
              router.push('/myAccount')
            })
            .catch(() => {
              return false
            })

          return
        }
      }

      if (approvalRadio.value !== 5) {
        emit('handleSave', { orderApproval: approvalRadio.value.toString() })
      } else {
        if (timeSlot.value === 'PM') {
          const newTime = time.value + 12
          emit('handleSave', { orderApproval: '5:' + newTime })
        } else {
          emit('handleSave', { orderApproval: '5:' + time.value })
        }
      }
    }
    return {
      approvalRadio,
      timeSlot,
      time,
      timeOptions,
      soltOptions,
      saveApproval,
      isSave,
    }
  },
}
</script>

<style lang="scss" scoped>
.info {
  font-size: 21px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 29px;
  margin-bottom: 12px;
}
.account_info {
  width: 75%;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.5);
  // line-height: 22px;
}
.padding {
  padding-left: 30px;
  margin: 10px 0;
}
.hint {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.btn {
  margin: 30px;
}
</style>
